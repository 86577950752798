/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

// import 'jquery/dist/jquery.min.js'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
// import "./src/assets/vendor/bootstrap/css/bootstrap.min.css"
import "./src/assets/vendor/icofont/icofont.min.css"
import "./src/assets/vendor/boxicons/css/boxicons.min.css"
import "./src/assets/vendor/remixicon/remixicon.css"
// import "./src/assets/vendor/venobox/venobox.css"
// import "./src/assets/vendor/owl.carousel/assets/owl.carousel.min.css"
// import "./src/assets/vendor/aos/aos.css"
import "./src/assets/css/style.css"
import './src/assets/js/cca.js'

// import './src/assets/vendor/jquery/jquery.min.js'
// import './src/assets/vendor/bootstrap/js/bootstrap.bundle.min.js'
// import './src/assets/vendor/jquery.easing/jquery.easing.min.js'
// import './src/assets/vendor/php-email-form/validate.js'
// import './src/assets/vendor/waypoints/jquery.waypoints.min.js'
// import './src/assets/vendor/counterup/counterup.min.js'
// import './src/assets/vendor/venobox/venobox.min.js'
// import './src/assets/vendor/owl.carousel/owl.carousel.min.js'
// import './src/assets/vendor/isotope-layout/isotope.pkgd.min.js'
// import './src/assets/vendor/aos/aos.js'
// import './src/assets/js/main.js'
 

// 
// import 'popper.js/dist/popper.min'
// import 'bootstrap/js/dist/util'
// import 'bootstrap/js/dist/carousel'
// import 'bootstrap/js/dist/dropdown'